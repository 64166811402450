import { Component, OnInit, PLATFORM_ID, Inject } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { Router } from '@angular/router';
import { CommanservicesService } from 'src/app/services/commanservices.service';
import { Meta, Title } from '@angular/platform-browser';
declare const $: any;
@Component({
  selector: 'app-new-footer',
  templateUrl: './new-footer.component.html',
  styleUrls: ['./new-footer.component.scss']
})
export class NewFooterComponent implements OnInit {
  public isCategoryLoading: boolean = false;
  public pagination: any = { pageNum: 1, numPerPage: 10 }
  public categoryList: any = []
  public data: any;
  public brandList: any = []
  public orgnizationData: any = {};
  public storesList: any = []
  public footerData: any = {};
  orgnizationData1: any = {};
  public isLoading: boolean = false;
  orgnizationData2: any;
  organizationAddress: any;
  orgnizationData4: any;
  footerData10: string;
  public orgnizationAddr: String = '';
  constructor(
    public dataService: CommanservicesService,
    private title: Title,
    private meta: Meta,
    @Inject(PLATFORM_ID) private platformId: Object,
    public router: Router,) {
    if (isPlatformBrowser(this.platformId)) {
      this.categoryList = JSON.parse(sessionStorage.getItem("categoryList"))
      this.dataService.getMessage().subscribe((msg) => {
        if (msg == 'categoryUpdated') {
          this.categoryList = JSON.parse(sessionStorage.getItem("categoryList"))
        }
      })
    }
  }

  ngOnInit(): void {
    this.getBrands();
    this.orgnizationData1 = JSON.parse(localStorage.getItem("User_Org"));



    this.orgnizationData4 = JSON.parse(localStorage.getItem("GET_WAREHOUSE"));
    
    this.footerData10 = JSON.stringify(localStorage.getItem("User_Org"));

    this.orgnizationData = this.dataService.currentMessage.subscribe(message => this.orgnizationData = message);
  
    this.getStore();
    this.getFooterData();




    this.orgnizationData2 = JSON.parse(localStorage.getItem("User_Org"));
    this.organizationAddress = this.orgnizationData2.adressList[0].address;

    const footerDataString = JSON.stringify(this.footerData);

    // this.footerData = JSON.parse(localStorage.getItem("User_Org"));

    // this.organizationAddress = this.orgnizationData2.adressList[0].address;


  }


  public getCategory(): void {
    this.isCategoryLoading = true;
    let item = { "pageNum": 1, "numPerPage": 20, "searchKey": null, "saveFilter": "", "userFilter": [] }
    this.dataService.openPostRequest(item, this.dataService.appConstant.SERVER_URLS['GET_CATEGORIES']).subscribe(result => {
      this.data = result;
      if (this.dataService.appConstant.SUCCESS === this.data.status) {
        this.categoryList = this.data.result;
        sessionStorage.setItem('categoryList', JSON.stringify(this.categoryList))
        this.dataService.sendMessage("categoryUpdated")
        this.isCategoryLoading = false;
      } else {
        this.isCategoryLoading = false;
        this.dataService.appConstant.errorMsg(this.data.message);
      }
    }, err => {
      this.isCategoryLoading = false;
    });
  }


  getStore() {
    this.dataService.getRequest(this.dataService.appConstant.SERVER_URLS["GET_WAREHOUSE"]).subscribe(result => {
      this.data = result;
      if (this.dataService.appConstant.SUCCESS === this.data.status) {
        this.storesList = this.data.result;
        if (this.storesList && this.storesList.length > 0) {
          if (this.storesList[0].street1) {
            this.orgnizationAddr = this.orgnizationAddr + this.storesList[0].street1 + ","
          }
          if (this.storesList[0].street2) {
            this.orgnizationAddr = this.orgnizationAddr + this.storesList[0].street2 + ","
          }
          if (this.storesList[0].city) {
            this.orgnizationAddr = this.orgnizationAddr + this.storesList[0].city + ","
          }
          if (this.storesList[0].state) {
            this.orgnizationAddr = this.orgnizationAddr + this.storesList[0].state + ","
          }
          if (this.storesList[0].country) {
            this.orgnizationAddr = this.orgnizationAddr + this.storesList[0].country + ","
          }
          if (this.storesList[0].zipCode) {
            this.orgnizationAddr = this.orgnizationAddr + this.storesList[0].zipCode + ""
          }
        }
      }
    }, err => {
    })
  }
  public getBrands(): void {
    this.isLoading = true;
    this.dataService.openPostRequest(this.pagination, this.dataService.appConstant.SERVER_URLS['BRAND_LIST']).subscribe(result => {
      this.data = result;
      if (this.dataService.appConstant.SUCCESS === this.data.status) {
        this.brandList = this.data.result;
        sessionStorage.setItem('brandList', JSON.stringify(this.brandList))
        this.dataService.sendMessage("brandsUpdated")
        this.isLoading = false;
      } else {
        this.isLoading = false;
        this.dataService.appConstant.errorMsg(this.data.message);
      }
    }, err => {
      this.isLoading = false;
    });
  }


  ngOnDestroy() {
    this.orgnizationData.unsubscribe();
    // this.categoryloadedSub.unsubscribe();
  }


  scrollTop() {
    window.scroll(0, 0);
  }


  // goToPrivacyPolicy(tab) {
    
  //   if (tab == 'Privacy_Policy') {
  //     this.dataService.sendMessage("privacyPolicy")
  //   }else if(tab=='About_Us'){
  //     this.dataService.sendMessage("About_Us")
  //   } else if(tab=='Terms_and_Conditions') {
  //     this.dataService.sendMessage("tearmCondition")
  //   } else if(tab=='Return_Policy') {
  //     this.dataService.sendMessage("returnPolicy")
  //   }
  //   this.router.navigate(['web/policyPage', tab]);
  // }
  goToPrivacyPolicy(tab) {
    console.log('TAB==========>>',tab);
    if (tab == 'Privacy_Policy') {
      this.dataService.sendMessage("privacyPolicy")
    }else if(tab=='About_Us'){
      this.dataService.sendMessage("About_Us")
    } else if(tab=='Terms_and_Conditions') {
      this.dataService.sendMessage("tearmCondition")
    } else if(tab=='Return_Policy') {
      this.dataService.sendMessage("returnPolicy")
    }else if(tab=='Shipping_Policy'){
      this.dataService.sendMessage("shippingPolicy")
    }
    this.router.navigate(['web/policyPage', tab]);
  }


  getFooterData() {
    this.dataService.getRequest(this.dataService.appConstant.SERVER_URLS["GET_FOOTER_DATA"]).subscribe(result => {
      this.data = result;
      if (this.dataService.appConstant.SUCCESS === this.data.status) {
        this.footerData = this.data.result;
  
      }
    }, err => {
    })
  }




}
