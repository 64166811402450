import { Component, OnInit, OnDestroy, Pipe, PipeTransform, ViewChild } from '@angular/core';
import { CommanservicesService } from 'src/app/services/commanservices.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { Location } from '@angular/common';
import { Meta, Title } from '@angular/platform-browser';
import { Select, Store } from '@ngxs/store';
import { PolicyState } from 'src/app/store/state/policy.state';
import { Observable } from 'rxjs';
import { PolicyData } from '../appModels/policy.model';
import { getPolicy } from 'src/app/store/actions/policy.action';
import { Subscription } from 'rxjs/Subscription';

@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.scss']
})


export class PrivacyPolicyComponent implements OnInit, OnDestroy {

  @Select(PolicyState.getPolicyData) policyObj$: Observable<PolicyData>;

  @Select(PolicyState.getPolicyLoaded) policyLoaded$: Observable<boolean>;

  policyLoadedSub: Subscription;

  public tab: any;
  data: any;
  getDetails: any = {};
  description: any;
  public isLoading: boolean = false;
  public skeletonSection: any = [{}, {}, {}, {}, {}, {}]
  public URL: any;
  currentMessage: string;
  constructor(
    public dataService: CommanservicesService,
    public router: Router,
    public route: ActivatedRoute,
    public sanitizer: DomSanitizer,
    public _location: Location,
    private title: Title,
    private store: Store,
    private meta: Meta,
  ) {

    this.route.params.subscribe(params => {
      this.tab = params.tab
      if (this.tab === 'Privacy_Policy') {
        this.dataService.sendMessage('privacyPolicy');
        this.currentMessage = 'privacyPolicy';
        
        this.URL = "PRIVACY_POLICY";

      } else if (this.tab === 'About_Us') {
        this.dataService.sendMessage('About_Us');
        this.currentMessage = 'About_Us';
       
        this.URL = "ABOUT_US";

      } else if (this.tab === 'Return_Policy') {
        this.dataService.sendMessage('returnPolicy');
        this.currentMessage = 'returnPolicy';
       
        this.URL = "RETURN_POLICY";

      } else if (this.tab === 'Shipping_Policy') {
        this.dataService.sendMessage('shippingPolicy');
        this.currentMessage = 'shippingPolicy';
      
        this.URL = "SHIPPING_POLICY";

      } else if (this.tab === 'Terms_and_Conditions') {
        this.dataService.sendMessage('tearmCondition');
        this.currentMessage = 'tearmCondition';
       
        this.URL = "TERMS_AND_CONDITION";


      }
    });
  
    this.dataService.getMessage().subscribe((msg) => {
      if (msg == 'privacyPolicy') {
        this.URL = "PRIVACY_POLICY";
        this.getPrivacyPolicy();
      }

      else if (msg == 'tearmCondition') {
        this.URL = "TERMS_AND_CONDITION";
        this.getPrivacyPolicy();
      }

      else if (msg == 'About_Us') {
        this.URL = "ABOUT_US";
        this.getPrivacyPolicy();
      }

      else if (msg == 'returnPolicy') {
        this.URL = "RETURN_POLICY";
        this.getPrivacyPolicy();
      }

      else if (msg == 'shippingPolicy') {
        this.URL = "SHIPPING_POLICY";
        this.getPrivacyPolicy();
      }
      else if (this.currentMessage && this.URL) {
        this.getPrivacyPolicy();
      }
    })
  }
  ngOnInit(): void {
    this.getPrivacyPolicy();
  }
  back() {
    this._location.back();
  }

  getPrivacyPolicy() {
    this.isLoading = true;
    this.policyLoadedSub = this.policyLoaded$.subscribe(loadedPolicys => {
      if (!loadedPolicys) {
        this.store.dispatch(new getPolicy());
      }
    })
    this.getDetails = this.policyObj$.subscribe(result => {
      this.data = result;
      this.getDetails = result;
      this.description = this.sanitizer.bypassSecurityTrustHtml(this.getDetails.description)
      this.title.setTitle(this.URL);
      this.meta.updateTag({ name: 'description', content: this.description });
    })


  
    
    this.dataService.getRequest(this.dataService.appConstant.SERVER_URLS[this.URL]).subscribe(result => {
      this.data = result;
      if (this.dataService.appConstant.SUCCESS === this.data.status) {
        this.getDetails = this.data.result;
        this.description = this.sanitizer.bypassSecurityTrustHtml(this.getDetails.description)


        this.title.setTitle(this.URL);
        this.meta.updateTag({ name: 'description', content: this.description });

        this.isLoading = false;
      } else {
        this.isLoading = false;
      }
    }, err => {
      this.isLoading = false;
    })
  }

  ngOnDestroy() {
    this.policyLoadedSub.unsubscribe();
  }


}
